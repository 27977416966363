import React from 'react';
import s from './CreditButton.module.scss';

const CreditButton = ({ src, creditText, amountText, href }) => {

    return (
        <a className={s.btnContainer} href={href}>
          <button className={s.buttonContainer}>
              {<div className={s.iconContainer}>
                {src && <img src={src} alt='icono'/>}
                <span className={s.textContainer}>
                    <p className={s.creditText}>{creditText}</p>
                    <p className={s.amountText}>{amountText}</p>
              </span>
              </div>}
          </button>
        </a>
    )
};

export default CreditButton;
