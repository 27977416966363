import React from "react";
import "../style/style.module.scss";
import s from '../style/indexPage.module.scss';
import { useStaticQuery, graphql } from "gatsby";
import Header from '../components/Header';
import Graphic from '../components/Graphic';
import OurNumbers from '../components/OurNumbers';
import OurProcess from '../components/OurProcess';
import Blog from '../components/Blog';
import Media from '../components/Media';
import FaqsContainer from '../components/FaqsContainer';
import Experts from '../components/Experts';
import CTAFooter from '../components/CTAFooter';
import Footer from '../components/Footer';
import CreditButton from '../components/CreditButton';
import homeIcon from '../images/icons/icono-HE.svg';
import carIcon from '../images/icons/icono-CE.svg';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import MetaData from '../components/MetaData';
import FbPixel from '../components/FbPixel';
import {isMobile} from 'react-device-detect';
import PageView from '../components/PageView';
import { TOP_LEVEL_PREFIX, DESKTOP, LAPTOP, MOBILE } from '../config.js';


const IndexPage = () => {
    const productType = 'General';
    const data = useStaticQuery(graphql` {
        gcms {
            headerInfo: headers (where: {status: PUBLISHED, landingType: General}){
                landingType
                preTitle
                title
                subTitle{
                    html
                }
            }
            checks: headers(where: {landingType: General}){
                check1{
                    html
                }
                check2{
                    html
                }
                check3{
                    html
                }
            }
        },
        desktop: file(relativePath: { eq: "General.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 3840) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
        laptop: file(relativePath: { eq: "General-small.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
        mobile: file(relativePath: { eq: "General-mobile.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }`);
    const headers = data.gcms;
    let imageDataSrc = [
        {
            ...data.mobile.childImageSharp.fluid,
            media: MOBILE,
        },
    ];
    if (!isMobile) {
        imageDataSrc = [
            {
                ...data.desktop.childImageSharp.fluid,
                media: DESKTOP,
            },
            {
                ...data.laptop.childImageSharp.fluid,
                media: LAPTOP,
            },
        ];
    }
    return(
        <div>
            <MetaData/>
            <FbPixel productType={productType}/>
            <TagManager/>
            <PageView/>
            <Header preTitle={headers.headerInfo[0].preTitle} title={headers.headerInfo[0].title}
              subTitle={headers.headerInfo[0].subTitle.html} className={s.headerContainer} backgrounds={imageDataSrc}
              checks={headers.checks} productType={productType}
            >
            <CreditButton href={`${TOP_LEVEL_PREFIX}/app/ce/completa-tus-datos/mi-prestamo`} src={carIcon} creditText={<span>Crédito con garantía <strong>VEHICULAR</strong></span>} amountText={<span>Mínimo <strong>AR 50.000</strong> desde TNA 63% </span>}/>
            <CreditButton href={`${TOP_LEVEL_PREFIX}/app/hd/completa-tus-datos/mi-prestamo`} src={homeIcon} creditText={<span>Crédito con garantía <strong>HIPOTECARIA</strong></span>} amountText={<span>Mínimo <strong>US 15.000</strong> desde TNA 18%</span>}/>
            </Header>
            <Graphic/>
            <OurNumbers/>
            <OurProcess/>
            <Media/>
            <Blog/>
            <FaqsContainer productType={productType} showAll={true}/>
            <Experts/>
            <CTAFooter>
                Averiguá sin compromiso a qué crédito podrías acceder
            </CTAFooter>
            <Footer productType={productType}/>
            <TagManagerFooter/>
        </div>
    )
}

export default IndexPage
